import React from "react";
import EditableParagraph from "../../UI/EditableParagraph";
import "../../../styles/UserProfilePage.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    addLanguage,
    updateLanguage,
    removeLanguage,
    updateUser,
} from "../../../actions/userActions";
import LanguagesInProfile from "./LanguagesInProfile";
import UserInfo from "./UserInfo";
import constants from "../../../config/constants";

const UserProfilePage = ({ user, updateUser, ...props }) => {
    if (!user) return "Loading...";

    const save = () => updateUser(user);

    const logout = () => {
        window.localStorage.removeItem(constants.ACCESS_TOKEN); // delete token from local storage
        props.history.push("/login");
    };

    return (
        <div className="UserProfilePage-container">
            <div className="UserProfilePage">
                <UserInfo user={user} updateUser={updateUser} />
                <div className="About-container">
                    <AboutUser
                        updateUser={updateUser}
                        user={user}
                        bio={user && user.profile && user.profile.bio}
                    />
                    <LanguagesInProfile
                        {...props}
                        user={user}
                        updateUser={updateUser}
                    />
                </div>
                <div className="UserProfilePage__save-container">
                    <button onClick={save} className="UserProfilePage__save">
                        save
                    </button>
                    <button onClick={logout}>logout</button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
    languages: state.userReducer.languages,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addLanguage,
            updateLanguage,
            removeLanguage,
            updateUser,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);

const AboutUser = ({
    bio = "Please enter a short description about you.",
    updateUser,
    user,
}) => {
    const update = (text) => {
        updateUser({
            ...user,
            profile: {
                ...user.profile,
                bio: text,
            },
        });
    };

    return (
        <div className="AboutUser">
            <label>About me</label>
            <EditableParagraph update={update} user={user}>
                {bio}
            </EditableParagraph>
        </div>
    );
};
