import React from "react";
import { NavLink, Link } from "react-router-dom";
import "../../styles/Navbar.scss";

const links = [
    {
        href: "/published",
        label: "Published",
    },
    {
        href: "/drafts",
        label: "Drafts",
    },
    {
        href: "/",
        label: "All",
    },
];

// Check if the router filter is the current one
// being looped over
const getActiveName = (routerFilter, filter) =>
    RegExp(routerFilter).test(filter) ? "selected" : "";

const Navbar = ({
    match: {
        params: { filterId },
    },
}) => (
    <nav className="Navbar">
        <ul>
            {links.map((link) => (
                <li key={link.href}>
                    <NavLink
                        to={link.href}
                        activeClassName={getActiveName(filterId, link.href)}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ))}
        </ul>
        <div className="Navbar__mid">
            <Link to="/shared" className="Navbar__action">
                Shared
            </Link>
        </div>
        <Link to="/book/new" className="Navbar__action">
            <span className="mdc-icon-button material-icons">add</span>
            Add
        </Link>
    </nav>
);

export default Navbar;
